import React,{useContext, useState} from 'react';
import NepaliDate from 'json/nepali_date_v1.json';
import { ResponseSnackbar, ShowSnackbar } from 'layout/snackbar/Snackbar';
import { AlertMessage } from 'layout/alert/Alert';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import { ApiRequest, CreateImageBlob, UrlParams } from './APICall';
import CssVariables from 'styles/scss/variables.scss';
import toWords from 'number-to-words/src/toWords';

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, exdays=-1) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  if(exdays==-1)
    document.cookie = cname + "=" + cvalue + ";" + ";path=/";
  else
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function EncryptString(data){
  var cipher= CryptoAES.encrypt(data, process.env.REACT_APP_SECRET_KEY).toString();
  return cipher;
}
export function DecryptString(data){
  var bytes  = CryptoAES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
  var originalText = bytes.toString(CryptoENC);
  return originalText;
}
export function EncryptObject(data){
  var cipher=CryptoAES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
  return cipher;
}
export function DecryptObject(data){
  var bytes  = CryptoAES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
  var decryptedData = JSON.parse(bytes.toString(CryptoENC));
  return decryptedData;
}

/**
 * Capitalizing first letter of each word.
 * @param {*} str 
 */
export function TitleCase(str) {
  if(str==undefined) return "";
    var wordsArray = str.toLowerCase().split(/\s+/);
    var upperCased = wordsArray.map(function(word) {
      return word.charAt(0).toUpperCase() + word.substr(1);
    });
    return upperCased.join(" ");
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function updateQueryString(name, value) {
  var url = window.location.href.split('?')
  var queryParameter = getJsonFromUrl()
  queryParameter[name] = value
  url = url[0] + "?" + UrlParams(queryParameter)
  updatePageUrl(url)
}

/**Read query string */
export function readQueryString(name,defaultValue=""){
    var url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return defaultValue;
    if (!results[2]) return defaultValue;
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**Change page url without redirecting. */
export function updatePageUrl(url){
  window.history.pushState('Aegis', 'Aegis', url);
}


/**Parse all query string. */
export function getJsonFromUrl(url) {
  if(!url) url = window.location.search;
  if(url=="") return {};
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function(part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

/**
 * Checks if role is assigned.
 * @roles Roles data from api call.
 * @role_id can be one value or array of ids. i.e '-2' or ['-2','-3]. If any of the array value is true than the role is assigned.
 */
export function IsRoleAssigned(roles,role_id){
  var roleAssigned=false;
  try{
    if(Array.isArray(role_id)){
      role_id.forEach(r=>{
        if(roles.filter(x=>x.role_id==r).length>0)
          roleAssigned = true;
      });
    }else{
      if(roles.filter(x=>x.role_id==role_id).length>0)
        roleAssigned = true;
    }
  }catch(error){

  }
  return roleAssigned;
}

/**
 * Returns class of display none if role is not assigned.
 * @roles Roles data from api call.
 * @role_id can be one value or array of ids. i.e '-2' or ['-2','-3]. If any of the array value is true it becomes visible.
 */
export function RoleCheck(roles,role_id){
    if(IsRoleAssigned(roles,role_id))
      return '';
    return ' display-none ';
}

/**
 * Add Ctrl + key event
 *key keycode
 *callback to be called.
 */
export function AddEvent_CTRL(key,callback){
  key=key.toUpperCase().charCodeAt(0);//Converting to keyCode
  var fun=function(e) {
    if (e.ctrlKey == true && e.keyCode == key){
      e.preventDefault();
      callback();
    }
  }
  window.addEventListener('keydown', fun);
  return ()=>{window.removeEventListener('keydown',fun)};
}

/**
 * Key press event for function key.
 * @key 1...12 function keys
 * @callback 
 */
export function AddEvent_FUNCTION(key,callback){
  
  var base=111;
  key=base+parseInt(key);//112 to 123
  
  var fun=function(e) {

    if (e.keyCode == key){
      e.preventDefault();
      callback();
    }
  }
  window.addEventListener('keydown', fun);
  return ()=>{window.removeEventListener('keydown',fun)};
}

/**
 * Add Alt + key event
 *key keycode
 *callback to be called.
 */
export function AddEvent_ALT(key,callback){
  key=key.toUpperCase().charCodeAt(0);//Converting to keyCode
  var fun=function(e) {
    if (e.altKey == true && e.keyCode == key){
      e.preventDefault();
      callback();
    }
  }
  window.addEventListener('keydown', fun);
  return ()=>{window.removeEventListener('keydown',fun)};
}

/**
 * Add key event for ESC
 *key keycode
 *callback to be called.
 */
export function AddEvent_ESC(callback){
  var fun=function(e) {
    if (e.keyCode == "27"){
      e.preventDefault();
      callback();
    }
  }
  window.addEventListener('keydown', fun);
  return ()=>{window.removeEventListener('keydown',fun)};
}

/**
 * Callback is called when window is focused.
 * @param {*} callback 
 */
export function WindowFocused(callback){
  var fun=function(e){
    e.preventDefault();
    callback();
  };
  window.addEventListener('focus', fun);
  return ()=>{window.removeEventListener('focus',fun)};
}

/**
 * It is called for addition input from pop up
 * @param {*} url 
 */
export function NewWindow(url, always_open_new_window = false, showOnFullWindow = false, isSubWindow = false){
  var id="AEGIS";//uuidv4();
  if(always_open_new_window == true){
    id = "AEGIS " + uuidv4()
  }

  let window_height = window.innerHeight - 50
  let window_width = window.innerWidth - 50
  let position_from_top = 50

  if(showOnFullWindow) {
    window_height = window.screen.availHeight
    window_width = window.screen.availWidth
    position_from_top = 0
  }

  var newWindow = window.open('', `Aegis${id}`, `top=${position_from_top},width=${window_width},height=${window_height}`);
  
  let urlConfigParam = ''
  if(isSubWindow) urlConfigParam = '&isw=true'
  
  newWindow.location.href = url + urlConfigParam;
  newWindow.focus();
  return newWindow;
}

/**
 * It is for checking if the argument is JSON or not.
 * @param {*} str
 */
export function IsJSONString(str){

  try {
    JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

/**
 * It is for checking if two dates are same day or not.
 * Wrap value to new Date()
 * @param {*} str
 */
export function IsSameDate(firstDate, secondDate) {
  return (firstDate.getFullYear() === secondDate.getFullYear()) && (firstDate.getMonth() === secondDate.getMonth()) && (firstDate.getDate() === secondDate.getDate())
}


/**
 * Check if date is today or tomorrow
 * @param {*} date
 */
export function CheckDateTimeline(date) {
  var date_timeline = new Date(date);
  var today = new Date(GetAcDate());
  var tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (date_timeline.getMonth() === today.getMonth() && date_timeline.getDate() === today.getDate()) {
    return "TODAY";
  } else if (date_timeline.getMonth() === tomorrow.getMonth() && date_timeline.getDate() === tomorrow.getDate()) {
    return "TOMORROW";
  } else {
    return "";
  }

}

/**
 * Load config.json from public directory. Get the value based on key. 
 */
export function AppConfig(key){
  
  //If no api_url is set, call to self
  if(key=="api_url" && window.app_config[key]==""){
    return window.location.origin+"/api"
  }
  return window.app_config[key];
}

/**
 * Used to get date format of the application.
 */
export function DateFormat(){
  if(localStorage.date_format==null || localStorage.date_format=="null")
    return "dd/MM/yyyy";
  if(localStorage.date_format.toLowerCase()=="yyyy/MM/dd".toLowerCase()){
    return "yyyy/MM/dd";
  }else if(localStorage.date_format.toLowerCase()=="MM/dd/yyyy".toLowerCase()){
    return "MM/dd/yyyy";
  }else {
    return "dd/MM/yyyy";
  }
}

/**
 * Used to set date format of the application.
 */
export function SetDateFormat(date_format){
  return localStorage.date_format=date_format;
}

/**
 * This function return accounting date of system. It is in date format.
 */
export function GetAcDate(){
  if(localStorage.ac_date===null || localStorage.ac_date==="null")
    return null;
  return new Date(localStorage.ac_date);
}

/**
 * This function set accounting date in localstorage.
 */
export function SetAcDate(ac_date){
  localStorage.ac_date=ac_date;
}

/**
 * It converts date into the date format of the system in string format. It is just for showing purpose cant be used directly as date.
 * @param {*} date 
 */
export function ToDateString(date){
  if(date==null) return "";
  date=new Date(date);
  var year=date.getFullYear();
  var month=date.getMonth();
  //Month is based on 0 index so adding 1
  month=month+1;
  var day=date.getDate();

  //Adding 0 to month & day if it is less than 10
  month=(parseInt(month)<10?"0"+month:month);
  day=(parseInt(day)<10?"0"+day:day);

  var format=DateFormat();

  // if(format==null || format=="null"){
  //   ShowSnackbar('warning',"Please setup property information.");
    
  //   if(window.location.pathname!=="/app/setup/propertysetup"){
  //     AlertMessage({type:'warning',title:'Setup Incomplete!',message:'Please setup property information.',btnTypeId:1,confirmCallBack:()=>{
  //       window.location.href="/app/setup/propertysetup?t=2";
  //     },btnConfirmText:"Ok"});
  //   }
  // }
    

    if(format.toLowerCase()=="yyyy/mm/dd"){
      return year+"/"+month+"/"+day;
    }else if(format.toLowerCase()=="dd/mm/yyyy" || format==null || format=="null"){
      return day+"/"+month+"/"+year;
    }else if(format.toLowerCase()=="mm/dd/yyyy"){
      return month+"/"+day+"/"+year;
    }
}

/**
 * Check if the date parameter is a date or not.
 * @param {*} date 
 * @returns 
 */
export function IsDateString(date){
  
  var format=DateFormat();
  var splitted_dates=[];
  date=date.toString().substring(0,10);//date is 10 char long
  
  if(date.includes('/'))
    splitted_dates=date.toString().split("/");
  else if(date.includes('-')){
    splitted_dates=date.toString().split("-");
    format="yyyy/MM/dd";
  }else{
    return false;//not a date
  }
    
  if(splitted_dates.length!=3) return false;//not a date

  //Converting to MMDDYYYY
  var year=0;
  var month=0;
  var day=0;
  
  if(format.toLowerCase()=="yyyy/mm/dd"){
    year=splitted_dates[0];
    month=splitted_dates[1];
    day=splitted_dates[2];
  }else if(format.toLowerCase()=="dd/mm/yyyy" || format==null || format=="null"){
    year=splitted_dates[2];
    month=splitted_dates[1];
    day=splitted_dates[0];
  }else if(format.toLowerCase()=="mm/dd/yyyy"){
    year=splitted_dates[2];
    month=splitted_dates[0];
    day=splitted_dates[1];
  }

  var d=new Date(month+"/"+day+"/"+year)
  if(d instanceof Date && !isNaN(d))
    return true;
  
  return false;
}

/**
 * It converts date into the date format of the system in string format. It is just for showing purpose cant be used directly as date.
 * @param {*} date 
 */
 export function ToDateTimeString(date){
  if(date==null) return "";
  date=new Date(date);
  var year=date.getFullYear();
  var month=date.getMonth();
  //Month is based on 0 index so adding 1
  month=month+1;
  var day=date.getDate();

  //Adding 0 to month & day if it is less than 10
  month=(parseInt(month)<10?"0"+month:month);
  day=(parseInt(day)<10?"0"+day:day);

  var format=DateFormat();

  // if(format==null || format=="null"){
  //   ShowSnackbar('warning',"Please setup property information.");
    
  //   if(window.location.pathname!=="/app/setup/propertysetup"){
  //     AlertMessage({type:'warning',title:'Setup Incomplete!',message:'Please setup property information.',btnTypeId:1,confirmCallBack:()=>{
  //       window.location.href="/app/setup/propertysetup?t=2";
  //     },btnConfirmText:"Ok"});
  //   }
  // }
    

    var time_string=date.toLocaleTimeString(
        navigator.language, {// to show only hour and minute ignoring seconds
        hour: '2-digit',
        minute: '2-digit'
    })

    if(format.toLowerCase()=="yyyy/mm/dd"){
      return year+"/"+month+"/"+day+" "+time_string;
    }else if(format.toLowerCase()=="dd/mm/yyyy" || format==null || format=="null"){
      return day+"/"+month+"/"+year+" "+time_string;
    }else if(format.toLowerCase()=="mm/dd/yyyy"){
      return month+"/"+day+"/"+year+" "+time_string;
    }
}


/**
 * 
 * @param {*} array // Dropdown List/Array
 */
export function GetUniqueDDL(array) {
  return array.filter(function (a, b, c) {
    //  Get new unique list on the basis of value
    return c.findIndex( x => x.value == a.value ) === b;
  })
}

/**
 * Breaking array into different chunk based on chunkSize parameter. [1,2,3,4] of chunkSize 3 is [1,2,3],[4]
 */
export function ArrayChunk(array,chunkSize) {
  return [].concat.apply([],
    array?.map(function(elem, i) {
      return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
    })
  );
}

/**
 * Breaking last chunk from chunk of array into different chunk based on chunkSize parameter. [1,2,3,4] of chunkSize 3 is [1,2,3],[4]
 */
export function BreakArrayToChunksForPrint(array, rowsPerPage, lastChunkSize) {
  //  At first break the provide array into chunks of size rowsPerPage
  var newArrayBreakedToChunks = ArrayChunk(array, rowsPerPage)
  //  Get last chunk from it
  var lastChunk = newArrayBreakedToChunks[newArrayBreakedToChunks.length - 1]
  //  Again break the last chunk in size of lastChunkSize
  var lastChunkReChunked = ArrayChunk(lastChunk, lastChunkSize)
  //  We do this because footer cannot be printed seperately from body. There must be at least 1 row in tbody.

  //  Remove last chunk from main array's chunk
  newArrayBreakedToChunks.pop()
  //  Concat remaining chunks and lastChunkReChunked array
  var finalArray = newArrayBreakedToChunks.concat(lastChunkReChunked)
  return finalArray
}

export function ConvertNepaliDateDefaultFormatTo_YYYYMMDD(date, seperator){
  //debugger;
  var date_format = DateFormat();
  var date=date.split(seperator);
  var year;
  var month;
  var day;
  if (date_format == "MM/dd/yyyy") {
    year=date[2];
    month=date[0];
    day=date[1]
    
    return year+""+month+""+day;
  } else if (date_format == "yyyy/MM/dd") {
    year=date[0];
    month=date[1];
    day=date[2]
    
    return year+""+month+""+day;
  } else if (date_format == "dd/MM/yyyy") {
    year=date[2];
    month=date[1];
    day=date[0]
    
    return year+""+month+""+day;
  }
}

export function ConvertNepaliDate_YYYYMMDD_To_DefaultFormat(date, seperator="/"){
  //debugger;
  var date_format = DateFormat();
  //var date=date.split(seperator);
  var year;
  var month;
  var day;

  year=date.substr(0,4);
  month=date.substr(4,2);
  day=date.substr(6,2);


  if (date_format == "MM/dd/yyyy") {
    return month+seperator+day+seperator+year;
  } else if (date_format == "yyyy/MM/dd") {
    return year+seperator+month+seperator+day;
  } else if (date_format == "dd/MM/yyyy") {
    return day+seperator+month+seperator+year;
  }
}

/**
 * It returns date which is of string type. You can convert AD to date using new Date(date);
 * @type : 1 AD to BS || 2 BS to AD
 * @dateString : yyyy-mm-dd
 * @seperator : By default - is seperator. It must be same as dateString seperator.
 */
export function ConvertDate(type,dateString,seperator="-"){
  var date=dateString.split(seperator);
  var date_format = DateFormat();
  var newDate="";
  if (type == 1) {
    if (date_format == "MM/dd/yyyy") {
      newDate = NepaliDate.filter(x => x.english_year == date[2] && x.english_month == date[0] && x.english_day == date[1]);
      if (newDate.length == 0)
        return null;
      return newDate[0].nepali_month.toString().padStart(2, '0') + seperator + newDate[0].nepali_day.toString().padStart(2, '0') + seperator + newDate[0].nepali_year ;
    } else if (date_format == "yyyy/MM/dd") {
      newDate = NepaliDate.filter(x => x.english_year == date[0] && x.english_month == date[1] && x.english_day == date[2]);
      if (newDate.length == 0)
        return null;
      return newDate[0].nepali_year + seperator + newDate[0].nepali_month.toString().padStart(2, '0') + seperator + newDate[0].nepali_day.toString().padStart(2, '0');
    } else if (date_format == "dd/MM/yyyy") {
      newDate = NepaliDate.filter(x => x.english_year == date[2] && x.english_month == date[1] && x.english_day == date[0]);
      if (newDate.length == 0)
        return null;
      return newDate[0].nepali_day.toString().padStart(2, '0') + seperator + newDate[0].nepali_month.toString().padStart(2, '0') + seperator + newDate[0].nepali_year;
    }

  }
    
  else if(type==2){

    if (date_format == "MM/dd/yyyy") {
      ///////
      newDate=NepaliDate.filter(x=>x.nepali_year==date[2] && x.nepali_month==date[0] && x.nepali_day==date[1]);
      if(newDate.length==0)
        return null;
      return ((newDate[0].english_year+"-"+newDate[0].english_month.toString().padStart(2, '0')+"-"+newDate[0].english_day.toString().padStart(2, '0')))+" 00:00:00"
      ///////
    } else if (date_format == "yyyy/MM/dd") {
      ///////
      newDate=NepaliDate.filter(x=>x.nepali_year==date[0] && x.nepali_month==date[1] && x.nepali_day==date[2]);
      if(newDate.length==0)
        return null;
      return ((newDate[0].english_year+"-"+newDate[0].english_month.toString().padStart(2, '0')+"-"+newDate[0].english_day.toString().padStart(2, '0')))+" 00:00:00"
      ///////
    } else if (date_format == "dd/MM/yyyy") {
     ///////
     newDate=NepaliDate.filter(x=>x.nepali_year==date[2] && x.nepali_month==date[1] && x.nepali_day==date[0]);
     if(newDate.length==0)
       return null;
     return ((newDate[0].english_year+"-"+newDate[0].english_month.toString().padStart(2, '0')+"-"+newDate[0].english_day.toString().padStart(2, '0')))+" 00:00:00"
     ///////
    }  
  }
  
  return null;
  
}

// export var ExportToExcel= (function(id) {
//   var uri = 'data:application/vnd.ms-excel;base64,'
//     , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
//     , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
//     , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
//   return function(table, name) {
//     if (!table.nodeType) table = document.getElementById(table)
//     var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
//     window.location.href = uri + base64(format(template, ctx))
//   }
// })();

export var ExportListToExcel=function(tableId, name,excludedColumns) {

  var uri = 'data:application/vnd.ms-excel;base64,'
    , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
    , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }

   
  if (!tableId.nodeType) tableId = document.getElementById(tableId);
  var tableString="";

  //Removing html element like buttons & input
  for(var i=0;i<tableId.rows.length;i++){
    var rows=tableId.rows[i];
    //For Row = 1 i.e filter row, do not include it.
    if(i!=1) tableString+="<tr>";
    for(var j=0;j<tableId.rows[i].cells.length;j++){
      //If the column is excluded do not print it.
      //if(excludedColumns.indexOf(j)!=-1){}
      if(true){
        //In 1 row there is filter input, select. So do not include it.
        if(i!=1){
          var cell=tableId.rows[i].cells[j];
          if(cell.tagName.toLowerCase()=="th")
            tableString=tableString+"<th>"+cell.innerText+"</th>";
          else
            tableString=tableString+"<td>"+cell.innerText+"</td>";
        }
      }
    }
    //For Row = 1 i.e filter row, do not include it.
    if(i!=1) tableString+="</tr>";
    console.log(tableString);
  }
  

  var ctx = {worksheet: name || 'Worksheet', table: tableString}
  
  var finalUri = uri + base64(format(template, ctx));
  // Construct the <a> element
  var link = document.createElement("a");
  if(name==undefined) name ="List";
  link.download = name+'.xls';
  link.href = finalUri;

  document.body.appendChild(link);
  link.click();

  // Cleanup the DOM
  document.body.removeChild(link);
};

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;

}

export const PrintReportTable=(tableId,property_info,title, hide_filter=false)=>{
  if (!tableId.nodeType) tableId = document.getElementById(tableId);
  var tableString=document.getElementById("as-report-cover").innerHTML;
  tableString="<p style='text-align:center;font-size:14px'>"+property_info.property_name+" "+property_info.multi_property_code+"</p>"
            +"<p style='text-align:right;font-size:14px'>PAN: "+property_info.pan+"</p>"
            +"<p style='text-align:center;font-size:14px'>"+title+"</p>"
            +(hide_filter ? '' : document.getElementById("as-report-filter").outerHTML)
            +tableString;
  //////////////
  let mywindow = window.open('', 'PRINT'+uuidv4(), 'height=650,width=900,top=100,left=150');
  mywindow.document.write(`<html><head><title>Report Print</title>`);
  mywindow.document.write('</head><body >');
  mywindow.document.write(`<style>
  .as-report-body-wrapper {
    width: 100%;
    background-color: #fff;
    padding: 10px 0px;
 }
  .as-report-body-wrapper table {
    width: 100% !important;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 12px;
    margin: 0;
    padding: 0;
    // -webkit-print-color-adjust: exact !important;
    // print-color-adjust: exact !important;
 }
  .as-report-body-wrapper table thead tr th, .as-report-body-wrapper table thead tr td {
    background-color: #ffffff !important;
    font-size: 12px;
    color: #000000 !important;
    border-bottom: 1px solid #ccc;
    z-index: 4;
 }
  .as-report-body-wrapper table thead tr th table, .as-report-body-wrapper table thead tr td table {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
 }
  .as-report-body-wrapper table thead tr th table thead tr th, .as-report-body-wrapper table thead tr td table thead tr th, .as-report-body-wrapper table thead tr th table tbody tr th, .as-report-body-wrapper table thead tr td table tbody tr th, .as-report-body-wrapper table thead tr th table thead tr td, .as-report-body-wrapper table thead tr td table thead tr td, .as-report-body-wrapper table thead tr th table tbody tr td, .as-report-body-wrapper table thead tr td table tbody tr td {
    border: 0;
 }
 
  .as-report-body-wrapper table tbody tr th, .as-report-body-wrapper table tbody tr td {
    font-size: 12px;
    
 }
  .as-report-body-wrapper table tbody tr th table, .as-report-body-wrapper table tbody tr td table {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
 }
  .as-report-body-wrapper table tbody tr th table thead tr th, .as-report-body-wrapper table tbody tr td table thead tr th, .as-report-body-wrapper table tbody tr th table tbody tr th, .as-report-body-wrapper table tbody tr td table tbody tr th, .as-report-body-wrapper table tbody tr th table thead tr td, .as-report-body-wrapper table tbody tr td table thead tr td, .as-report-body-wrapper table tbody tr th table tbody tr td, .as-report-body-wrapper table tbody tr td table tbody tr td {
    border: 0;
 }
  
  .as-report-body-wrapper table tfoot tr th, .as-report-body-wrapper table tfoot tr td {
    font-size: 12px;
    
 }
  .as-report-body-wrapper table tfoot tr th table, .as-report-body-wrapper table tfoot tr td table {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
 }
  .as-report-body-wrapper table tfoot tr th table thead tr th, .as-report-body-wrapper table tfoot tr td table thead tr th, .as-report-body-wrapper table tfoot tr th table tbody tr th, .as-report-body-wrapper table tfoot tr td table tbody tr th, .as-report-body-wrapper table tfoot tr th table thead tr td, .as-report-body-wrapper table tfoot tr td table thead tr td, .as-report-body-wrapper table tfoot tr th table tbody tr td, .as-report-body-wrapper table tfoot tr td table tbody tr td {
    border: 0;
 }
  

  .as-table-wrapper {
    width: 100%;
    
 }
  
  table thead tr.as-primary-highlight th, table tbody tr.as-primary-highlight th, table tfoot tr.as-primary-highlight th, table thead tr.as-primary-highlight td, table tbody tr.as-primary-highlight td, table tfoot tr.as-primary-highlight td {
    background-color: #e9f7fd !important;
 }
  table thead tr.as-secondary-highlight th, table tbody tr.as-secondary-highlight th, table tfoot tr.as-secondary-highlight th, table thead tr.as-secondary-highlight td, table tbody tr.as-secondary-highlight td, table tfoot tr.as-secondary-highlight td {
    background-color: #fafafa !important;
 }
  table tr.as-primary-highlight th, table tr.as-primary-highlight td {
    background-color: #e9f7fd !important;
 }
  tr.as-primary-highlight {
    background-color: #e9f7fd !important;
 }
  tr.as-primary-highlight th, tr.as-primary-highlight td {
    background-color: #e9f7fd !important;
 }
  tr.as-secondary-highlight.dark th, tr.as-secondary-highlight.dark td {
    background-color: #d9d9d9 !important;
    color: #121b34;
 }
  tr.as-secondary-highlight.light th, tr.as-secondary-highlight.light td {
    background-color: #f2f2f2 !important;
 }
  tr.as-secondary-highlight.red th, tr.as-secondary-highlight.red td {
    background-color: #fde1df !important;
    color: #710e06 !important;
 }
  tr.as-secondary-highlight.yellow th, tr.as-secondary-highlight.yellow td {
    background-color: #ffecd1 !important;
    color: #6b4000 !important;
 }
  tr.as-secondary-highlight.green th, tr.as-secondary-highlight.green td {
    background-color: #c7e7c8 !important;
    color: #163317 !important;
 }
  tr.as-secondary-highlight.blue th, tr.as-secondary-highlight.blue td {
    background-color: #d1e8ff !important;
    color: #00366b !important;
 }
  tr.as-secondary-highlight.theme th, tr.as-secondary-highlight.theme td {
    background-color: #acdff7 !important;
    color: #052939 !important;
 }
  
/************BELOW HERE IS TABLE CSS, UP IS REPORT CSS**********************/
 table.as-table {
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 12px;
  transform: scale(0.9);
  transform-origin: top left;
}
table.as-table tr td, table.as-table tr th {
  
  display: table-cell;
  text-align: left;
  vertical-align: top;
  color: #000000 !important;
}
table.as-table tr td:first-child, table.as-table tr th:first-child {
  padding-left: 16px;
}
table.as-table tr.rowspan-sibling td:first-child, table.as-table tr.rowspan-sibling th:first-child {
  padding-left: 1px;
}
table.as-table-all {
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 12px;
  border: 1px solid #d8d8d8;
}
table.as-table-all tr:nth-child(odd) {
  background-color: #fff;
}
table.as-table-all tr:nth-child(even) {
  background-color: #f4f4f4;
}
table.as-table-all tr td, table.as-table-all tr th {
  display: table-cell;
  text-align: left;
  vertical-align: top;
}
table.as-table-all tr td:first-child, table.as-table-all tr th:first-child {
  padding-left: 16px;
}
table.as-table-all tr.rowspan-sibling td:first-child, table.as-table-all tr.rowspan-sibling th:first-child {
  padding-left: 1px;
}
table.as-table-fixed {
  table-layout: fixed;
}
table.as-table-bordered tr {
  border-bottom: 1px solid #ccc;
}
table.as-table-bordered-all thead tr th, table.as-table-bordered-all tbody tr th, table.as-table-bordered-all tfoot tr th, table.as-table-bordered-all thead tr td, table.as-table-bordered-all tbody tr td, table.as-table-bordered-all tfoot tr td {
  border: 1px solid #ccc !important;
}
table.as-table-bordered-all tr th, table.as-table-bordered-all tr td {
  border: 1px solid #ccc !important;
}
table.as-table-striped tbody tr:nth-child(even) {
  background-color: rgba(102, 102, 102, 0.05);
}
table.as-table-hoverable tbody tr:hover {
  background-color: rgba(102, 102, 102, 0.1);
}
table.as-table-hoverable tbody tr:focus-within {
  background-color: rgba(76, 175, 80, 0.2);
}
table.as-table-responsive {
  overflow-x: auto;
}
table.as-table-centered tr td, table.as-table-centered tr th {
  text-align: center;
}
table.as-table-with-input {
  table-layout: fixed;
}
table.as-table-with-input thead tr th, table.as-table-with-input thead tr td {
  vertical-align: middle;
}
table.as-table-with-input tbody tr th, table.as-table-with-input tfoot tr th, table.as-table-with-input tbody tr td, table.as-table-with-input tfoot tr td {
  vertical-align: middle;
}
table.as-table-with-input tbody tr th input, table.as-table-with-input tfoot tr th input, table.as-table-with-input tbody tr td input, table.as-table-with-input tfoot tr td input {
}
table tr.as-pagination-btn-row {
  box-shadow: none !important;
}
table tr.as-pagination-btn-row th, table tr.as-pagination-btn-row td {
  padding-left: 0 !important;
}
table tr.error-row td, table tr.error-row th {
  background-color: rgba(244, 67, 54, 0.2);
}
table.error-row-icon {
  color: #f44336;
}
table.as-table-shadowed {
  border-collapse: unset !important;
  border-spacing: 4px !important;
}
table.as-table-shadowed thead tr, table.as-table-shadowed tfoot tr {
  box-shadow: 0px 2px 4px #ccc;
}
table.as-table-shadowed thead tr th, table.as-table-shadowed tfoot tr th, table.as-table-shadowed thead tr td, table.as-table-shadowed tfoot tr td {
  background: #fff;
  border: none;
  color: #121b34;
}
table.as-table-shadowed thead tr th:first-child, table.as-table-shadowed tfoot tr th:first-child, table.as-table-shadowed thead tr td:first-child, table.as-table-shadowed tfoot tr td:first-child {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
table.as-table-shadowed thead tr th:last-child, table.as-table-shadowed tfoot tr th:last-child, table.as-table-shadowed thead tr td:last-child, table.as-table-shadowed tfoot tr td:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
table.as-table-shadowed tbody tr {
  box-shadow: 0px 2px 4px #ccc;
}
table.as-table-shadowed tbody tr th, table.as-table-shadowed tbody tr td {
  background: #fff;
  border: 1px solid transparent;
  color: #555;
}
table.as-table-shadowed tbody tr th:first-child, table.as-table-shadowed tbody tr td:first-child {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
table.as-table-shadowed tbody tr th:last-child, table.as-table-shadowed tbody tr td:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
table.as-table-shadowed tbody tr.as-pagination-btn-row {
  box-shadow: none !important;
}
table.as-table-shadowed tbody tr.as-pagination-btn-row th, table.as-table-shadowed tbody tr.as-pagination-btn-row td {
  padding-left: 0 !important;
}
table.as-table-shadowed thead tr th {
  text-transform: capitalize !important;
}
table.as-table-shadowed.as-table-with-extra-row {
  border-collapse: unset !important;
  border-spacing: 4px !important;
}
table.as-table-shadowed.as-table-with-extra-row tbody tr:nth-child(odd) {
  box-shadow: 0px -1px 2px #ccc;
}
table.as-table-shadowed.as-table-with-extra-row tbody tr:nth-child(even) {
  box-shadow: 0px 1px 2px #ccc;
}
table.as-table-shadowed.as-table-with-extra-row tbody tr:nth-child(even) th, table.as-table-shadowed.as-table-with-extra-row tbody tr:nth-child(even) td {
  vertical-align: middle;
}
table.as-table-shadowed.as-table-with-extra-row tbody tr:nth-child(even) th div, table.as-table-shadowed.as-table-with-extra-row tbody tr:nth-child(even) td div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}
table.as-table-shadowed.as-table-with-extra-row tbody tr:nth-child(even) th div label, table.as-table-shadowed.as-table-with-extra-row tbody tr:nth-child(even) td div label {
}

.as-hidden-row {
  display:none;
}

.as-hidden-filter {
  display: none !important;
}

.as-hidden-row.on-web {
  display:table-row;
  border: none;
}
/********BREADCRUMB**************/

.as-breadcrumb {
  display: inline-block;
  background-color: transparent;
  width: 100%;
  padding: 0;
  margin-bottom: 0;
}
.as-breadcrumb-list {
  display: inline;
  list-style: none;
  cursor: pointer;
  font-size: 14px;
}
.as-breadcrumb-list:not(:last-of-type)::after {
  content: "/";
  margin: 0 5px;
  color: rgba(85, 85, 85, 0.6);
}
.as-breadcrumb-link {
  color: #555;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;
}
.as-breadcrumb-link:hover {
  text-decoration: underline;
  color: #149ddd;
}
.as-breadcrumb-link.as-breadcrumb-active {
  color: #149ddd;
}


  </style>`);
  mywindow.document.write(tableString);
  mywindow.document.write("<p style='text-align:right;font-size:14px' id='as-report-footer'><b>Report By : "+localStorage.user_name+" | "+ToDateTimeString(new Date())+"</b></p>")
  mywindow.document.write("<p style='text-align:center;font-size:14px' id='as-report-footer'><b>Powered By: Aegis Software</b></p>")
  mywindow.document.write('</body></html>');

  
  mywindow.document.close();
  mywindow.focus();
  mywindow.print();
  setTimeout(() => mywindow.close(), 1000)

}

export var ExportTableToExcel=function(tableId, name, in_blob=false, property_info,title, hide_filter=false,htmlComponent=null) {

  var uri = 'data:application/vnd.ms-excel;base64,'
    , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
    , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }

   
  //if (!tableId.nodeType) tableId = document.getElementById(tableId);
  if(document.getElementsByClassName('as-hidden-row').length>0) document.getElementsByClassName('as-hidden-row')[0].innerHTML=''
  var tableString=htmlComponent == null ? document.getElementById(tableId).innerHTML : htmlComponent;
  tableString=tableString.replace(new RegExp("<thead>",'g'),'')
  tableString=tableString.replace(new RegExp("</thead>",'g'),'')
  
  tableString="<tr><td style='text-align:left;font-size:14px' colspan='5'><b>"+property_info.property_name+" "+property_info.multi_property_code+"</b></td></tr>"
            +"<tr><td style='text-align:left;font-size:14px' colspan='5'><b>"+title+"</b></td></tr>"
            +"<tr><td colspan='5'>"+(document.getElementById("as-report-filter")==null || hide_filter ?"":document.getElementById("as-report-filter").outerHTML)+"</td></tr>"
            +tableString
            +"<tr></tr><tr><td colspan='5'><b>Report By : "+localStorage.user_name+" | "+ToDateTimeString(new Date())+"</b></td></tr>"
            
  var ctx = {worksheet: name || 'Worksheet', table: tableString}
  
  var finalUri = uri + base64(format(template, ctx));

  if(in_blob==true){
    return dataURItoBlob(finalUri);
  }

  // Construct the <a> element
  var link = document.createElement("a");
  if(name==undefined) name ="List";
  link.download = name+'.xls';
  link.href = finalUri;

  document.body.appendChild(link);
  link.click();

  // Cleanup the DOM
  document.body.removeChild(link);
};

export var ExportTableToCsv=function(tableId, name, in_blob=false, property_info,title, hide_filter=false,htmlComponent=null) {
  
    var csv = [];
    debugger
    var rows = document.getElementById(tableId).querySelectorAll('table tr');

    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll('td, th');
        debugger
        for (var j = 0; j < cols.length; j++) {
            row.push('"'+cols[j].innerText+'"');
        }
        debugger
        csv.push(row.join(','));
    }

    // Create a Blob and create a download link
    var blob = new Blob([csv.join('\n')], { type: 'text/csv;charset=utf-8;' });
    var link = document.createElement('a');

    if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

};


/**
 * Returns array of empty string
 * @param {Number} arrayLength - Total number of blank string.
 * @returns {Array} Array of empty string
 */
export const generateBlankValueForCSV = (arrayLength = 1) => {
  var blankValueArray = []
  while (blankValueArray.length < arrayLength) {
      blankValueArray.push('')
  }
  return blankValueArray
}


/**
 * Prepare and download CSV
 * @param {Array} data - Array of arrays
 */
export const downloadCSV = (data, fileName) => {
debugger  
  // Assuming data is an array of arrays, where each inner array represents a row of data. For e.g: [[1,2,3], [4,5,6], ...]
  const csv = data.map(x => x.map(value => {
      // Avoid commas (Because CSV creates new cell after comma)
      if (typeof value === 'string' && value.includes(',')) {
          // Escape any double quotes in the value by doubling them
          value = value.replace(/"/g, '""');
          // Enclose the value in double quotes
          return `"${value}"`;
      }
      return value;
  }).join(',')).join('\n');   // '\n' is for new row

  const csvBlob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(csvBlob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}


/**
 * Called from Pagination List
 * @param {*} tableId 
 */
export var PrintListDataInPDF=(tableId,name,excludedColumns)=>{

  if(name==undefined) name="List";
  
  tableId = document.getElementById(tableId);

  var tableString="<table>";

  //Removing html element like buttons & input
  for(var i=0;i<tableId.rows.length;i++){
    var rows=tableId.rows[i];
    tableString+="<tr>";
    for(var j=0;j<tableId.rows[i].cells.length;j++){
      //If the column is excluded do not print it.
      if(excludedColumns.indexOf(j)!=-1){}
      else{
        var cell=tableId.rows[i].cells[j];
        //In 1 row there is filter input, select. It will generate 
        if(i!=1){
          if(cell.tagName.toLowerCase()=="th")
            tableString=tableString+"<th >"+cell.innerText+"</th>";
          else
            tableString=tableString+"<td >"+cell.innerText+"</td>";
        }
      }
    }
    tableString+="</tr>";
  }

  tableString+="</table>";

  console.log(tableString);

  let mywindow = window.open('', 'PRINT'+uuidv4(), 'height=650,width=900,top=100,left=150');

  mywindow.document.write(`<html><head><title>${name}</title>`);
  mywindow.document.write('</head><body >');
  mywindow.document.write(tableString);
  mywindow.document.write('</body></html>');

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  //mywindow.print();
  //mywindow.close();

  return true;
}

export var POSPrintDialog=async(html,css,headerSize,waitForImageLoad=true,qrsize=0,logosize=0,outlet_id= 0,bill_type='')=>{
  
  let mywindow = window.open('', 'PRINT'+uuidv4(), 'height=650,width=900,top=100,left=150');
  mywindow.document.write(`<html><head><title>POS</title>`);
  mywindow.document.write('</head><body >');
  mywindow.document.write(`<style>${css}</style>`);
  mywindow.document.write(html);
  
  if (bill_type == 'a4' || bill_type == 'a5') {
    await SetImageForPrinting("header", mywindow, outlet_id, bill_type);
  } else if (bill_type == 'conti') {
    await SetImageForPrinting("logo-image", mywindow, outlet_id, bill_type);    
    await SetImageForPrinting("qr-image", mywindow, outlet_id, bill_type);    
  }
debugger;
  //For header height
  mywindow.document.write(`<style>
  img#header-image {
    min-height: ${headerSize}mm;
    max-height: ${headerSize}mm;
    max-width:100%;
    min-width:100%;
  }
  </style>`);
  //For QR
  mywindow.document.write(`<style>
  img#qr-image {
    min-height:${qrsize}mm;
    max-height:${qrsize}mm;
    max-width:${qrsize}mm;
    min-width:${qrsize}mm;
  }
  </style>`);
  
  //For Logo
  mywindow.document.write(`<style>
  img#logo-image {
    min-height: ${logosize}mm;
    max-height: ${logosize}mm;
    max-width:${logosize}mm;
    min-width:${logosize}mm;
  }
  </style>`);
  mywindow.document.write('</body></html>');

  //if(!waitForImageLoad){  
    mywindow.document.close();    
    setTimeout(()=>{
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    },2000)
 // }
  
 
  return true;
}
export var FOPrintDialog=(html,css,headerSize,waitForImageLoad=true)=>{
  
  let mywindow = window.open('', 'PRINT'+uuidv4(), 'height=650,width=900,top=100,left=150');
  mywindow.document.write(`<html><head><title>FO</title>`);
  mywindow.document.write('</head><body >');
  mywindow.document.write(`<style>${css}</style>`);
  mywindow.document.write(html);

  //For printing after image is loaded
  if(waitForImageLoad)
    mywindow.document.write('<script type="text/javascript">var img = document.getElementById("header-image"); var src = img.src; img.addEventListener("load", function() { window.stop(); window.print(); setTimeout(()=>window.close(),1000) }); img.src = ""; img.src = src;</script>');

  //For header height
  mywindow.document.write(`<style>
    img#header-image {
        min-height: ${headerSize}mm;
        max-height: ${headerSize}mm;
        max-width:100%;
        min-width:100%;
    }
  </style>`);
  mywindow.document.write('</body></html>');

  if(!waitForImageLoad){
    mywindow.document.close();    
    setTimeout(()=>{
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    },1000)
  }

  return true;
}

export var FORsvnSlipPrintDialog=(html,css,headerSize,waitForImageLoad=true,bodyClassName='')=>{
  
  let mywindow = window.open('', 'PRINT'+uuidv4(), 'height=650,width=900,top=100,left=150');
  var headerLink='<link href="/css/suneditor.css" rel="stylesheet" />';
  mywindow.document.write(`<html><head><title>FO</title>`);
  {headerLink && mywindow.document.write(headerLink)}
  mywindow.document.write(`<style type="text/css">${css}</style>`);
  mywindow.document.write(`</head><body class=${bodyClassName}>`);
  mywindow.document.write(html);

  //For printing after image is loaded
  if(waitForImageLoad)
    mywindow.document.write('<script type="text/javascript">var img = document.getElementById("header-image"); var src = img.src; img.addEventListener("load", function() { window.stop(); window.print(); setTimeout(()=>window.close(),1000) }); img.src = ""; img.src = src;</script>');

  //For header height
  mywindow.document.write(`<style>
    img#header-image {
        min-height: ${headerSize}mm;
        max-height: ${headerSize}mm;
        max-width:100%;
        min-width:100%;
    }
  </style>`);
  mywindow.document.write('</body></html>');

  if(!waitForImageLoad){
    mywindow.document.close();    
    setTimeout(()=>{
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    },1000)
  }

  return true;
}

export var INVPrintDialog = (html, css, headerSize, waitForImageLoad = true) => {

  let mywindow = window.open('', 'PRINT'+uuidv4(), 'height=650,width=900,top=100,left=150');
  mywindow.document.write(`<html><head><title>FO</title>`);
  mywindow.document.write('</head><body >');
  mywindow.document.write(`<style>${css}</style>`);
  mywindow.document.write(html);

  //For printing after image is loaded
  if (waitForImageLoad)
    mywindow.document.write('<script type="text/javascript">var img = document.getElementById("header-image"); var src = img.src; img.addEventListener("load", function() { window.stop(); window.print(); setTimeout(()=>window.close(),1000) }); img.src = ""; img.src = src;</script>');

  //For header height
  mywindow.document.write(`<style>
    img#header-image {
        min-height: ${headerSize}mm;
        max-height: ${headerSize}mm;
        max-width:100%;
        min-width:100%;
    }
  </style>`);
  mywindow.document.write('</body></html>');

  if (!waitForImageLoad) {
    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    setTimeout(() => mywindow.close(), 1000)
  }

  return true;
}
export var FOVoucherPrintDialog = (html, headerSize,css, waitForImageLoad=true) => {
  debugger

  let mywindow = window.open('', 'PRINT'+uuidv4(), 'height=650,width=900,top=100,left=150');
  mywindow.document.write(`<html><head><title>FO</title>`);
  mywindow.document.write('</head><body >');
  mywindow.document.write(`<style>${css}</style>`);
  mywindow.document.write(html);

  //For printing after image is loaded
  if(waitForImageLoad)
  mywindow.document.write('<script type="text/javascript">var img = document.getElementById("header-image"); var src = img.src; img.addEventListener("load", function() { window.stop(); window.print(); setTimeout(()=>window.close(),1000) }); img.src = ""; img.src = src;</script>');

  //For header height
  mywindow.document.write(`<style>
    img#header-image {
        min-height: ${headerSize}mm;
        max-height: ${headerSize}mm;
        max-width:100%;
        min-width:100%;
    }
  </style>`);
  mywindow.document.write('</body></html>');

  if (!waitForImageLoad) {
    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    setTimeout(() => mywindow.close(), 1000)
  }

  return true;
}

export var ACPrintDialog = (html, css, headerSize, waitForImageLoad = true) => {

  let mywindow = window.open('', 'PRINT'+uuidv4(), 'height=650,width=900,top=100,left=150');
  mywindow.document.write(`<html><head><title>AC</title>`);
  mywindow.document.write('</head><body >');
  mywindow.document.write(`<style>${css}</style>`);
  mywindow.document.write(html);

  //For printing after image is loaded
  if (waitForImageLoad)
    mywindow.document.write('<script type="text/javascript">var img = document.getElementById("header-image"); var src = img.src; img.addEventListener("load", function() { window.stop(); window.print(); setTimeout(()=>window.close(),1000) }); img.src = ""; img.src = src;</script>');

  //For header height
  mywindow.document.write(`<style>
    img#header-image {
        min-height: ${headerSize}mm;
        max-height: ${headerSize}mm;
        max-width:100%;
        min-width:100%;
    }
  </style>`);
  mywindow.document.write('</body></html>');

  if (!waitForImageLoad) {
    setTimeout(()=>{
      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(() => mywindow.close(), 1000)
    },1000)
  }

  return true;
}

export var HKHousekeepingTaskPrintDialog = (html, css) => {
  let mywindow = window.open('', 'PRINT' + uuidv4(), 'height=650, widht=900,top=100,left=150');
  mywindow.document.write('<html><head><title>HK</title>');
  mywindow.document.write('</head><body>');
  mywindow.document.write(`<style>${css}</style>`);
  mywindow.document.write(html);
  mywindow.document.write('</body></html>');
  return true;
}

export function removeMultipleSpacesToOneSpace(string) {
  return string.replace(/ +/g, ' ');
}

/**
 * 
 * @param {Number to separate with comma} num 
 * @param {Digits to display after decimal [Default 2]} afterDecimal 
 * @returns Number separated with commas.
 */
//  Seperate number with comma for amount.
export function numberWithCommas(num, afterDecimal = 2, hasComma = false) {
  let new_num = num
  // Remove existing commas and non-numeric characters
  if (hasComma) {
    new_num = num.toString().replace(/[^\d.-]|(?<=\..*)-/g, '')
    if (!new_num) return num
  }

  let numStr = parseFloat(new_num).toFixed(afterDecimal);
  if (numStr.includes('.')) {
    let [integerPart, decimalPart] = numStr.split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${integerPart}.${decimalPart}`;
  } else {
    return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

/**
 * Percentage difference between two values
 * @param {*} value1 
 * @param {*} value2 
 */
export function percentageDifference(value1, value2) {
  var percentage_difference =  ((value2 - value1) / value1) * 100;
  if(!isFinite(percentage_difference)) return parseFloat(parseFloat(value2).toFixed(6))
  if(isNaN(percentage_difference)) return parseFloat(parseFloat(value2 - value1).toFixed(6))
  return parseFloat(parseFloat(percentage_difference).toFixed(6))
}

export async function CallPassportScanner(){
  var data={};
  var URL='http://127.0.0.1:'+AppConfig('passport_scanner_nginx_port')+'/ps/SamsotechScannerService/Scan';
  
  await fetch(URL, {
    method: 'GET',
    })

    .then((response) => {
     
      return response.json()
    })
    .then((json) => {
   
      data=json
      data=JSON.parse(data);
      
      data.GivenName=(data.firstName||"")+" "+(data.middleName||"")+" "+(data.lastName||"");
      data.GivenName.replace("  ","");
      if(data.GivenName=="" || (data.gender||"")=="" || (data.martialStatus||"")==""){
        data.result=false;
      }
    }).catch((error) => {

      data= data={result:false}
    })

    var inputdata=[
      {
        "extension": "png",
        "file_name": "Passport Scan Copy",
        "file_content": data.fullImage,
        issue_country_code:[data.issueCountry,data.issueCountry_code2],

      }
    ]
    

    if(data.result){
      var attachment_id=await new Promise(resolve =>{
        ApiRequest({ TYPE:'POST', URL:'/app/Files/uploadbinarystring', CALLBACK:function(res){
          ResponseSnackbar(res);
          var attachment_id=null;
          if(res.data.uploaded_files.length>0){
            attachment_id=res.data.uploaded_files[0].id
            data.static_country_list_id=res.data.static_country_list_id
          }
          else
            data.result=false;
          resolve(attachment_id);
        }, COMPLETE:function(){
        }, DATA:inputdata })
      });
      if(attachment_id!=null)
        data.attachment_id=attachment_id;
    }
    
    
    return data;
    
}

export function StringToDate(str,type="dd/mm/yyyy"){
  if(str=="" || str==undefined)
    return null;
  if(type=="dd/mm/yyyy"){
    try{
      const [day, month, year] = str.split('/');
      const date = new Date(+year, month - 1, +day);
      return date;
    }catch{
      return null;
    }
  }
}



/**
 * Checks if first two values and second two values overlaps within each other range or not.
 * @param {*} first_start_value 
 * @param {*} first_end_value 
 * @param {*} second_start_value 
 * @param {*} second_end_value 
 */
export function rangeOverlaps(first_start_value, first_end_value, second_start_value, second_end_value) {
  if (first_start_value <= second_start_value && second_start_value <= first_end_value) return true
  if (first_start_value <= second_end_value && second_end_value <= first_end_value) return true
  if (second_start_value < first_start_value && first_end_value < second_end_value) return true
  return false
}

export function groupBy(arr, prop, keyName = 'key', listName = 'list') {
  const map = new Map(Array.from(arr, obj => [obj[prop], []]));
  arr.forEach(obj => map.get(obj[prop]).push(obj));
  return Array.from(map.values()).map( m => ({
    [keyName]: m[0][prop],
    [listName]: m
  }));
}

export async function SetImageForPrinting(imgType,mywindow,sub_module_id=0,bill_type=''){
  if (imgType == "header") {
    var header_image = mywindow.document.getElementById("header-image");
    if (header_image) {
      var url = bill_type == 'a5' ? AppConfig("api_url") + "/application/a5header?module=pos&&sub_module_id=" + sub_module_id
        : AppConfig("api_url") + "/application/a4header?module=pos&&sub_module_id=" + sub_module_id;;
      header_image.src = await CreateImageBlob(url);
      //return Promise.resolve(mywindow);
    }
  }
debugger;
  if (imgType == "qr-image") {
    //used get elements by name because there may be multile qr needed according to number of copies in bill
    var img_qr = mywindow.document.getElementsByName("qr-image");
    if (img_qr.length > 0) {
      var url = AppConfig("api_url") + "/application/qr?module=pos&&sub_module_id=" + sub_module_id;
      var src = await CreateImageBlob(url); 
      var temp_qr = [...img_qr];
      for (var i =0; i<temp_qr.length; i++){
        if(src == null){ // if there is no qr image then remove the img component for qr
          await temp_qr[i].remove();
        }else {
          temp_qr[i].src = src;
        }
      }
      img_qr = [...temp_qr]
             
      //return Promise.resolve(mywindow);
    }
  }

  if (imgType == "logo-image") {
    //used get elements by name because there may be multile logo needed according to number of copies in bill
    var img_logo = mywindow.document.getElementsByName("logo-image");
    if (img_logo.length > 0) {
      var url = AppConfig("api_url") + "/application/logo?module=pos&&sub_module_id=" + sub_module_id;       
        var src = await CreateImageBlob(url); 
        var temp_logo = [...img_logo]
      for (var i =0; i<temp_logo.length; i++){        
        if(src == null){ // if there is no logo image then remove the img component for logo
          temp_logo[i].remove();
        }else {
          temp_logo[i].src = src
        }
      }
      img_logo = [...temp_logo]        
      //return Promise.resolve(mywindow);
    }
  }   
}


/**
 * This function generates a random hexadecimal color code.
 */
export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}


/**
 * @param index Pass mapping index. Returns color code of that index (upto 20).
 * @param opacity Opacity for the color. [Default 100%] Range [00-99]
 */
export function getStaticColor(index = 0, opacity = '') {
  var colors = ["#53c0c0", "#ee5f83", "#36a2eb", "#9966fc", "#ff6e21", "#facd55", "#c9cbcf", "#2babab", "#ff6384", "#5959e6", "#009688", "#8bc34a", "#81B29A", "#607d8b", "#2ecc71", "#3498db", "#9b59b6", "#8c4d15", "#34495e", "#f1c40f", "#e67e22", "#e74c3c", "#1abc9c", "#2c3e50", "#7f8c8d", "#16a085", "#27ae60"]
  return `${colors[index]}${opacity}`
}


/**
 * Sorts array with list of key value paired objects.
 * @array Pass whole array you want to sort.
 * @sortingKey Key that you want to sort with.
 * 
 * NOTE: You must provide sortingKey. otherwise it will return the array as it is.
*/
export function sortAlphaNumericNestedArray(array, sortingKey) {

  //  This function splits the key into individual keys using the dot (.) as a separator,
  //  and then iterates over each key to retrieve the nested value from the object.
  function getNestedValue(obj, key) {
    const keys = key.split('.');
    let value = obj;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
    }
    return value;
  }

  var regAlphanumeric = /[^a-zA-Z]/g;
  var regNumeric = /[^0-9]/g;

  //  Perform Sorting
  array.sort((a, b) => {
    const nestedKeyA = getNestedValue(a, sortingKey);
    const nestedKeyB = getNestedValue(b, sortingKey);

    var aA = nestedKeyA.toString().replace(regAlphanumeric, "");
    var bA = nestedKeyB.toString().replace(regAlphanumeric, "");
    if (aA === bA) {
      var aN = parseInt(nestedKeyA.toString().replace(regNumeric, ""), 10);
      var bN = parseInt(nestedKeyB.toString().replace(regNumeric, ""), 10);
      return aN === bN ? 0 : (aN > bN ? 1 : -1);
    } else {
      return aA > bA ? 1 : -1;
    }
  })

  return array
}


/**
 * @array Pass the array you want to sort.
*/
export function sortAlphaNumericArray(array) {
  var regAlphanumeric = /[^a-zA-Z]/g;
  var regNumeric = /[^0-9]/g;
  array.sort((a, b) => {
    var aA = a.toString().replace(regAlphanumeric, "");
    var bA = b.toString().replace(regAlphanumeric, "");

    if (aA === bA) {
      var aN = parseInt(a.toString().replace(regNumeric, ""), 10);
      var bN = parseInt(b.toString().replace(regNumeric, ""), 10);
      return aN === bN ? 0 : (aN > bN ? 1 : -1);
    } else {
      return aA > bA ? 1 : -1;
    }
  })
  return array
}


/**
 * 
 * @param {Array to sum} arr 
 * @param {Key used for sum} key 
 * @returns number
 */
export function calculateTotalByKey(array, key) {
  return array.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
}


/**
 * 
 * @param {Array to sum} arr 
 * @param {Key used for sum} key 
 * @param {Filter by 'positive' value or 'negative' value. Default: 'positive'} valueOf
 * @returns number
 */
export function calculateTotalByPositiveNegativeKey(array, key, valueOf = 'positive') {
  if(valueOf == 'positive') {
    return array.filter(x => x[key] > 0).reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
  } else {
    return 0 - array.filter(x => x[key] < 0).reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
  }
}


/**
 * @number
 * Returns a new number with two decimal places rounded down to the nearest hundredth.
*/
export function parseFloorTwo(number) {
  return Math.floor(number * 100) / 100
}


/**
 * @text
 * Copies text to clipboard
 */
export function copyToClipboard(text) {
  navigator.clipboard.writeText(text);
  ShowSnackbar('success', `Copied to clipboard: ${text}`, 2000)
}

export function ConvertDateForAPI(date){
  
  if(date == null) return null;

  if(date == "") return "";

  var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


export function reportHeaderRowStyle() {
  return {
    backgroundColor: CssVariables.reportHeaderRowBg,
    color: '#ffffff',
    verticalAlign: 'middle'
  }
}

export function reportFirstLevelRowStyle() {
  return {
    backgroundColor: CssVariables.reportFirstLevelRowBg,
    color: CssVariables.defaultTextColorDark,
    verticalAlign: 'middle'
  }
}

export function reportSecondLevelRowStyle() {
  return {
    backgroundColor: CssVariables.reportSecondLevelRowBg,
    color: CssVariables.defaultTextColorDark,
    verticalAlign: 'middle'
  }
}

export function reportThirdLevelRowStyle() {
  return {
    backgroundColor: CssVariables.reportThirdLevelRowBg,
    color: CssVariables.defaultTextColorDark,
    verticalAlign: 'middle'
  }
}

export function reportFourthLevelRowStyle() {
  return {
    backgroundColor: CssVariables.reportFourthLevelRowBg,
    color: CssVariables.defaultTextColorDark,
    verticalAlign: 'middle'
  }
}

export function reportRowRedStyle() {
  return {
    backgroundColor: CssVariables.reportRowRedBg,
    color: CssVariables.reportRowRedTextColor,
    verticalAlign: 'middle'
  }
}

export function reportRowGreenStyle() {
  return {
    backgroundColor: CssVariables.reportRowGreenBg,
    color: CssVariables.reportRowGreenTextColor,
    verticalAlign: 'middle'
  }
}

export function reportRowYellowStyle() {
  return {
    backgroundColor: CssVariables.reportRowYellowBg,
    color: CssVariables.reportRowYellowTextColor,
    verticalAlign: 'middle'
  }
}

//  Used for Indentation in reports
export function indentationStyle(level = 0) {
  if(level == 0) return {}
  
  var levelPadding = 0
  if(level == 1) levelPadding = '30px'
  else if(level == 2) levelPadding = '45px'
  else if(level == 3) levelPadding = '60px'

  return {
    paddingLeft: levelPadding
  }
}

export function BillPrintService(ip,port,url,final_print_data,callback){
  var requestOptions1 = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(final_print_data)
  }; 

  fetch('http://'+ip+':'+port+url, requestOptions1)
      .then(response => response.json())
      .then(data => {
        //If the response is successful then  
        debugger;
        if(data.error_code == 0 ){
          ShowSnackbar("success",data.message);
          callback();
          
        }else if(data.error_code == 1){
          ShowSnackbar("error",data.message);
        }
        
  });
}

export async function IsBillPrintServiceOn(ip,port){
  var requestOptions1 = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
  }; 
  var ret=false;
  await fetch('http://'+ip+':'+port+"/healthcheck", requestOptions1)
        .then(response => response.json())
        .then(data => {
            if(data.success==true) ret = true;
        }).catch(function(err) {  
          return false;
        });
  return ret;
}

export function ChequePrintService(ip,port,data){
  debugger;
  var final_data=[];
  data.forEach(f=>{
      final_data.push({
        "ac_payee" : f.ac_payee ==null || f.ac_payee.toLowerCase()=="no"?false:true,
        "amount" : f.amount,
        "amount_in_words" : NumberToWords(f.amount).replace(/  +/g, ' ').toUpperCase(),
        "date" : f.cheque_date,
        "pay_to" : f.pay_to
      })
  })
  
  var requestOptions1 = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(final_data)
  }; 

  fetch('http://'+ip+':'+port+"/chequeprint/print", requestOptions1)
      .then(response => response.json())
      .then(data => {
          //callback();
  });
}

export function NumberToWords(data){  
  if(isNaN(parseFloat(data))){
    return "";
  }
  data = parseFloat(data).toFixed(2);

  var str = data.split('.');
  var number = parseInt(str[0]);
  var dec = parseInt(str[1].substring(0, 2));

  if (number == 0) return "Zero";

  if (number == -2147483648)
    return "Minus Two Hundred and Fourteen Crore Seventy Four Lakh Eighty Three Thousand Six Hundred and Forty Eight Rupees Fifty Paisa.";

  var num = new Array(4);
  var first = 0;
  var u, h, t;
  var sb = [];

  if (number < 0) {
    sb.push("Minus ");
    number = -number;
  }

  var words0 = ["", "One ", "Two ", "Three ", "Four ",
    "Five ", "Six ", "Seven ", "Eight ", "Nine "];

  var words1 = [
    "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ",
    "Fifteen ", "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

  var words2 = [
    "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ",
    "Seventy ", "Eighty ", "Ninety "];

  var words3 = [ "Thousand ", "Lakh ", "Crore " ];

  num[0] = parseInt(number % 1000);               // units 
  num[1] = parseInt(number / 1000);
  num[2] = parseInt(number / 100000);
  num[1] = parseInt(num[1] - 100 * num[2]);       // thousands 
  num[3] = parseInt(number / 10000000);           // crores 
  num[2] = parseInt(num[2] - 100 * num[3]);       // lakhs 

  for (var i = 3; i > 0; i--)
  {
    if (num[i] != 0) {
      first = i;
      break;
    }
  }


  for (var i = first; i >= 0; i--)
  {
    if (num[i] == 0) continue;

    u = parseInt(num[i] % 10);  // ones 
    t = parseInt(num[i] / 10);
    h = parseInt(num[i] / 100); // hundreds 
    t = parseInt(t - 10 * h);   // tens 

    if (h > 0) sb.push(words0[h] + "Hundred ");

    if (u > 0 || t > 0) {
      if (h > 0 && i == 0) sb.push("and ");

      if (t == 0)
        sb.push(words0[u]);
      else if (t == 1)
        sb.push(words1[u]);
      else
        sb.push(words2[t - 2] + words0[u]);
    }

    if (i != 0) sb.push(words3[i - 1]);

  }

  sb.push(" Rupees ");

  var d1 = parseInt(dec / 10);
  var d2 = parseInt(dec % 10);
  if (d1 == 0) {
    if (d2 == 0)
      sb.push(words0[d1]);
    else
      sb.push(words0[d2]);
  }
  else if (d1 == 1)
    sb.push(words1[d2]);
  else
    sb.push(words2[d1 - 2] + words0[d2]);

  if (dec > 0)
    sb.push(" Paisa");    
    
  return sb.join('').toString().trimEnd() + " only.";
}

//Function to convert number into words in millions pattern.
export function NumberToWordsEnglish(amount, prefix, suffix) {
  //To seperate currency_amount into before and after decimal parts
  const beforeAmount = Math.floor(amount);
  const afterAmount = Math.round((amount - beforeAmount) * 100).toString().padStart(2, '0');

  var amount_in_words = ` ${toWords(beforeAmount).replace(/  +/g, ' ').toUpperCase()} ${prefix.toUpperCase()} 
                            ${afterAmount > 0 ? ` AND ${toWords(afterAmount).replace(/  +/g, ' ').toUpperCase()} ${suffix.toUpperCase()}` : ''} 
                            ONLY.`
  return amount_in_words;                    
}


export const IsModalShowing=()=>{
  return document.getElementsByClassName("as-modal-popup-wrapper").length>0 ? document.getElementsByClassName("as-modal-popup-wrapper")[0].childElementCount>0 : false;
}

export const IsAttachmentModalOpened = () => {
  return document.getElementsByClassName('as-attachment-modal').length > 0
}

/**
 * Sends message to parent window from popup window. (This will only work on popup window)
 * @param {Object} data Data in JSON format
 * @param {Boolean} closeWindow Pass true or false
 */
export const sendMessageToMainWindow = (data, closeWindow = true) => {
  var is_sub_window = readQueryString('isw', '')
  if (window.opener != null && is_sub_window) {
    let messageToSend = {
        messageFrom: 'aegis_sub_window',
        ...data,
    }
    window.opener.postMessage(messageToSend, window.location.origin);
    if(closeWindow) window.close()
  }
}


/**
 * Returns object that matches value from the array.
 * @param {array} array Pass array that has key named 'value'.
 * @param {string} value Value to compare.
 * @returns {object}
 */
export const getDDLValueObject = (array, value) => {
  if(!Array.isArray(array)) return null
  if(value == undefined || value == null) return

  if (array.length > 0) {
      let filteredValueObject = array.filter(x => x.value == value)?.[0]
      return filteredValueObject
  }
  else return null
}


/**
 * Check if alert pop-up is open or not
 */
export const IsAlertOpen =() =>{
  var is_alert_open = document.getElementsByClassName("as-alert as-alert-show");
        if(is_alert_open.length > 0) {
          return true;
        }else {
          return false;
        }
          
}

export var FOTemplatePrint = (html, css) => {
  
  let mywindow = window.open('', 'PRINT' + uuidv4(), 'height=650,width=900,top=100,left=150');
  mywindow.document.write(`<html><head><title>FO</title>`);
  mywindow.document.write('</head><body >');
  mywindow.document.write(`<style>${css}</style>`);
  mywindow.document.write(html);
    
  mywindow.document.write('</body></html>');

  mywindow.document.close();
  
  setTimeout(() =>{
    mywindow.focus();
    mywindow.print();
    mywindow.close();
  }, 1000)

  return true;
}


/**
 * Separates entered numeric value with comma on real time.
 * @param {Number} value - Numeric value to separate with comma.
 */
export const liveCommaFormat = (value) => {
  if (!value) return value
  // Remove existing commas and non-numeric characters
  let new_value = value.toString().replace(/[^\d.-]|(?<=\..*)-/g, '')
            
  // Split the number into integer and decimal parts
  let parts = new_value.split('.')
  let integerPart = parts[0]
  let decimalPart = parts[1]
  
  // Add commas to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  
  // Combine integer and decimal parts
  if (decimalPart !== undefined) new_value = integerPart + '.' + decimalPart
  else new_value = integerPart

  return new_value
}


/**
 * Removes comma from provided value.
 * @param {String} value - Comma separated value
 * @returns {Number}
 */
export const commaToNumber = (value, decimalPlace = 2) => {
  if (!value) return value
  let new_value = value.toString().replace(/,/g, '')
  return parseFloat(parseFloat(new_value).toFixed(decimalPlace))
}


export const getNepaliDate = (english_date) => {
  if(english_date==null) return null;
  return ConvertNepaliDateDefaultFormatTo_YYYYMMDD(ConvertDate(1,ToDateString(new Date(english_date)),'/'),"/");
}


export const getEnglishDate = (nepali_date) => {
  if(nepali_date==null) return null;
  return ConvertDate(2,ConvertNepaliDate_YYYYMMDD_To_DefaultFormat(nepali_date),'/')
}


// //  Start Draggable Div
// /**
//  * Draggable Popup
//  */
// window.onload = function() {
//   var initDragElement = () => {
//     var pos1 = 0,
//       pos2 = 0,
//       pos3 = 0,
//       pos4 = 0;
//     var popups = document.getElementsByClassName("as-draggable-div");
//     var elmnt = null;
//     var currentZIndex = 100; //TODO reset z index when a threshold is passed
  
//     for (var i = 0; i < popups.length; i++) {
//       var popup = popups[i];
//       var header = getHeader(popup);
  
//       popup.onmousedown = function() {
//         this.style.zIndex = "" + ++currentZIndex;
//       };
  
//       if (header) {
//         header.parentPopup = popup;
//         header.onmousedown = dragMouseDown;
//       }
//     }
  
//     function dragMouseDown(e) {
//       elmnt = this.parentPopup;
//       elmnt.style.zIndex = "" + ++currentZIndex;
  
//       e = e || window.event;
//       // get the mouse cursor position at startup:
//       pos3 = e.clientX;
//       pos4 = e.clientY;
//       document.onmouseup = closeDragElement;
//       // call a function whenever the cursor moves:
//       document.onmousemove = elementDrag;
//     }
  
//     function elementDrag(e) {
//       if (!elmnt) {
//         return;
//       }
  
//       e = e || window.event;
//       // calculate the new cursor position:
//       pos1 = pos3 - e.clientX;
//       pos2 = pos4 - e.clientY;
//       pos3 = e.clientX;
//       pos4 = e.clientY;
//       // set the element's new position:
//       elmnt.style.top = elmnt.offsetTop - pos2 + "px";
//       elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
//     }
  
//     function closeDragElement() {
//       /* stop moving when mouse button is released:*/
//       document.onmouseup = null;
//       document.onmousemove = null;
//     }
  
//     function getHeader(element) {
//       var headerItems = element.getElementsByClassName("as-draggable-div-header");
  
//       if (headerItems.length === 1) {
//         return headerItems[0];
//       }
  
//       return null;
//     }
//   }
  
//   var initResizeElement = () => {
//     var popups = document.getElementsByClassName("as-draggable-div");
//     var element = null;
//     var startX, startY, startWidth, startHeight;
  
//     for (var i = 0; i < popups.length; i++) {
//       var p = popups[i];
  
//       var right = document.createElement("div");
//       right.className = "resizer-right";
//       p.appendChild(right);
//       right.addEventListener("mousedown", initDrag, false);
//       right.parentPopup = p;
  
//       var bottom = document.createElement("div");
//       bottom.className = "resizer-bottom";
//       p.appendChild(bottom);
//       bottom.addEventListener("mousedown", initDrag, false);
//       bottom.parentPopup = p;
  
//       var both = document.createElement("div");
//       both.className = "resizer-both";
//       p.appendChild(both);
//       both.addEventListener("mousedown", initDrag, false);
//       both.parentPopup = p;
//     }
  
//     function initDrag(e) {
//       element = this.parentPopup;
  
//       startX = e.clientX;
//       startY = e.clientY;
//       startWidth = parseInt(
//         document.defaultView.getComputedStyle(element).width,
//         10
//       );
//       startHeight = parseInt(
//         document.defaultView.getComputedStyle(element).height,
//         10
//       );
//       document.documentElement.addEventListener("mousemove", doDrag, false);
//       document.documentElement.addEventListener("mouseup", stopDrag, false);
//     }
  
//     function doDrag(e) {
//       element.style.width = startWidth + e.clientX - startX + "px";
//       element.style.height = startHeight + e.clientY - startY + "px";
//     }
  
//     function stopDrag() {
//       document.documentElement.removeEventListener("mousemove", doDrag, false);
//       document.documentElement.removeEventListener("mouseup", stopDrag, false);
//     }
//   }
//   initDragElement();
//   initResizeElement();
// };

// //  End Draggable Div
