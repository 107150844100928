import React,{useState, useEffect,useRef} from 'react';
import { ValidateSchema, DDL_Value, ErrorMessage, _GetFormData } from 'library/Form';
import "react-datepicker/dist/react-datepicker.css";
import usePrevious from 'hooks/useDeepEffect';
import { isEqual } from 'lodash';
import useDeepEffect from 'hooks/useDeepEffect';
import { DateFormat, removeMultipleSpacesToOneSpace } from 'library/Library';
import { ShowSnackbar } from 'layout/snackbar/Snackbar';
/**
 * {form,setForm,handle,fun,validate,resetForm}
 */
function useFormV1(initialDataCallback,validationSchemaCallback) {
    var initialData=initialDataCallback();
    
    const [form,setForm]=useState({
        input:initialData,
        error:{},
        touch:{}
    });

    useDeepEffect(()=>{
        validate(false);
    },[form.input]);

 
    //Find if it is react select.
    const isSelect=(e)=>{
        if(e==null)
            return true;
        if(e.target==undefined)
            return true;
        else 
            return false;
    }

    const forceValidate=(showSnackbar=true,input=form.input,validationSchema=null)=>{
        validate(true,input,validationSchema,showSnackbar);
    }

    /**
     * Call this to validate formInputData. It will update formInputData based on ddl validation and send it for validation.
     * @forceValidation true will show error message in all fields. else only to the field that is touched.
     */
    const validate=(forceValidation,input=form.input,validationSchema=null,showSnackbar=true)=>{
        
        //If form.input === intialData no need to show form validation message.

        if(isEqual(initialData,form.input) && !forceValidation){
            setForm(state=>(
                {
                    ...state,
                    error:{},
                    touch:{}
                }
            ));
            return;
        }

        if(validationSchema==null) validationSchema=validationSchemaCallback();

        if(forceValidation)
            if(!ValidateDDLS(input,validationSchema)){
                return;
            }
        
        var errors=ValidateSchema(input,validationSchema);

        setForm(state=>(
            {
                ...state,
                error:errors.message,
                touch:(forceValidation?errors.touch:state.touch)
            }
        ));
        
        if(Object.keys(errors.message).length==0) return true;//Valid
        else{
            if(forceValidation && showSnackbar){
                var TopOneMessage=errors.message[Object.keys(errors.message)[0]];
                ShowSnackbar("warning",TopOneMessage);
            }
            return false;
        } 
    }

    const ValidateDDLS=(formInputData,validationSchema)=>{
        
        var isValidDDL=true;
        // var validationSchema=validationSchemaCallback();

        var names = Object.keys(validationSchema);
        names.forEach(name => {
            var keys = Object.keys(validationSchema[name]);
            keys.forEach(key => {
                if(key=="ddl"){

                    var ddlOptions=validationSchema[name][key];
                    var ddlValue=_GetFormData(formInputData,name);
                    if(ddlValue==0) ddlValue="";//"" 0 or null means the value is accepted to be valid & need not to compare with existing options.
                    if(ddlOptions?.length==0 && ddlValue!==""){
                        isValidDDL=false;
                        
                        setForm(state=>(
                            {
                                ...state,
                                input:{
                                    ...state.input,
                                    [name]:null
                                },
                                touch:{
                                    ...state.touch,
                                    [name]:true
                                }
                            }
                        ));                

                        return false;//break inner loop
                    }else{

                        if(Array.isArray(ddlValue)){//Multi Select may return array with data or blank array
                            if(ddlValue==null || ddlValue.length==0 || ddlValue===""){}
                            else{

                                var optionValuesArray=ddlOptions.map(x=>x.value);

                                var valueNotFound=false;
                                ddlValue.forEach(value=>{
                                    if(optionValuesArray.filter(x=>x==value).length==0) valueNotFound=true;
                                })

                                //If any of the selected value is not found in ddlOptions than it is error.
                                if(valueNotFound){
                                    isValidDDL=false;

                                    setForm(state=>(
                                        {
                                            ...state,
                                            input:{
                                                ...state.input,
                                                [name]:null
                                            },
                                            touch:{
                                                ...state.touch,
                                                [name]:true
                                            }
                                        }
                                    ));  

                                    return false;//break inner loop
                                }
                            }
                        }else{//Normal Input

                            if(ddlValue===""){}

                            else{

                                //If input has some data. It must be validate with ddlOptions
                                if(ddlOptions?.filter(x=>x.value==ddlValue).length==0){
                                    isValidDDL=false;
                                    //If ddlOptions does not contain value data.

                                    setForm(state=>(
                                        {
                                            ...state,
                                            input:{
                                                ...state.input,
                                                [name]:null
                                            },
                                            touch:{
                                                ...state.touch,
                                                [name]:true
                                            }
                                        }
                                    ));  

                                    //break inner loop
                                }
                            }
                        }
                        //end else
                    }

                    
                }
            });
        });

        return isValidDDL;
    }

    const fun={
        //Used in search dropdown change
        searchChange:(e,name)=>{
            setForm(state=>(
                {
                    ...state,
                    input:{
                        ...state.input,
                        [name]:(e==null?null:e)
                    },
                }
            ));  
        },
        //Used in datepicker onChange
        dateChange:(e,name)=>{
           
            setForm(state=>(
                {
                    ...state,
                    input:{
                        ...state.input,
                        [name]:(e==null?null:e)
                    },
                }
            ));  
        },
        //This is used to set value in dropdown.
        ddlValue:(options,name)=>{
            var value;
            var newValue;
            try{
                value=form.input[name];
                newValue=DDL_Value(options,value);
            }catch(error){
                return null;
            }
            return newValue;
        },
        errorMessage:(name)=>{
            return (<ErrorMessage formInputError={form.error} formInputTouch={form.touch} name={name}/>)
        }
    };

    //Used in input and select props.
    const handle={
        onChange:(e,name)=>{
            if(!isSelect(e))
                e.persist();
            if(isSelect(e)){
                if(name==undefined)
                    throw "Please provide name for Select handle event.";
                var value;
                if(e==null){//If selected item/items is/are removed.
                    value=null;
                }else{
                    value=e.value;//If single select.
                    if(Array.isArray(e)){//If select is of type multi select.
                        value=e.map(x=>x.value);
                    }
                }
                setForm(state=>(
                    {
                        ...state,
                        input:{
                            ...state.input,
                            [name]:(e==null?null:value)
                        },
                    }
                )); 
                
            }
            
            if(name==undefined){
                name=e.target.name;
            }
            
            if(!isSelect(e)){
                if(e.currentTarget.type=="checkbox"){//If this is a checkbox
                    setForm(state=>(
                        {
                            ...state,
                            input:{
                                ...state.input,
                                [name]:e.target.checked
                            },
                        }
                    )); 
                  
                }
                else{//If this is input / radio.
                    setForm(state=>(
                        {
                            ...state,
                            input:{
                                ...state.input,
                                [name]:(e.target.value)
                            },
                        }
                    )); 
                  
                }
            }
            
            
            
        },
        onBlur:(e,name)=>{
            if(!isSelect(e))
                e.persist();

            if(name==undefined){
                name=e.target.name;
            }

            setTimeout(()=>{
                setForm(state=>(
                    {
                        ...state,
                        touch:{
                            ...state.touch,
                            [name]:true
                        },
                    }
                )); 
            },500);
 
          
        }
    }

    const resetForm=(initialFormData=initialData)=>{
        
        setForm({
            input:initialFormData,
            error:{},
            touch:{}
        }); 
    }

    /**
     * handle is json with onChange and onBlur
     */

    return {form,setForm,handle,fun,validate,resetForm,forceValidate};
}

export const filterOption = (option, inputValue) =>
  (option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || []).length > 0;


export default useFormV1;
